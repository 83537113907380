<template>
  <v-list-item v-if="data.status==='loading'">
    <template>
      <v-list-item-action>
        <div class="d-flex align-center mb-6 fill-height">
          <v-progress-circular
              :width="3"
              color="primary"
              indeterminate
          >{{ data.progress }}
          </v-progress-circular>
          <!--        <v-progress-circular-->
          <!--            :width="3"-->
          <!--            color="primary"-->
          <!--            :indeterminate="!data.progress"-->
          <!--            :value="data.progress"-->
          <!--        >{{ data.progress }}-->
          <!--        </v-progress-circular>-->
        </div>
      </v-list-item-action>

      <v-list-item-content>
        <v-skeleton-loader
            type="list-item-three-line"
        ></v-skeleton-loader>
      </v-list-item-content>

      <v-list-item-action class="fill-height">
        <v-skeleton-loader
            type="button"
        ></v-skeleton-loader>
      </v-list-item-action>
    </template>
  </v-list-item>
  <!--===========================================-->
  <v-list-item v-else>
    <template v-slot:default="{ active }">
      <v-list-item-action>
        <v-list-item-action-text/>
        <div class="d-flex align-center mb-6 fill-height">
          <v-icon v-if="!active"
                  color="grey lighten-1"
          >
            mdi-checkbox-blank-circle-outline
          </v-icon>
          <v-icon v-else
                  color="yellow darken-3"
          >
            mdi-checkbox-marked-circle-outline
          </v-icon>
        </div>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title v-text="data.textLine1"/>
        <v-list-item-subtitle class="text--primary" v-text="data.textLine2"/>
        <v-list-item-subtitle v-text="data.textLine3"/>
        <v-list-item-subtitle v-text="data.textLine4"/>
      </v-list-item-content>

      <v-list-item-action class="fill-height">

        <div
            class="d-flex mb-1"
        >

          <v-btn outlined x-small class="mr-1"
                 :disabled="data.status==='error'"
                 @click.stop="openHTMLpage()">
            HTML
          </v-btn>

          <v-btn outlined x-small
                 disabled
                 @click.stop="()=>{}">
            PDF
          </v-btn>

        </div>
        <v-btn outlined x-small block

               @click.stop="saveExtract()">
          СОХРАНИТЬ
        </v-btn>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  methods: {
    openHTMLpage() {
      // console.log('data', this.data);
      window.open(`${this.$store.state.domen}/server/api/v1/project/extractToHTML?name=${this.data.item.name}`, '_blank');
    },
    saveExtract() {
      this.$axios.post(this.$url.server_project_extractSave, {
        Cadastral: this.data.item.Cadastral,
        name: this.data.item.name
      })
          .then((response) => {
            this.$bus.emit('snackbar', {"title":this.data.item.Apartment,"text": this.$t(response.data.text)});
          })
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  font-size: xx-small;
}

</style>